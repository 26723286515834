<template>
  <div>
    <el-upload class="avatar-uploader" :action="this.$store.getters.imgUrl" accept=".jpg,.jpeg,.png,.gif,.ico" :headers="header" :show-file-list="false" :on-success="handleAvatarSuccess" :data="action">
      <img v-if="banner" :src="baseurl + yu + banner" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon" />
      <div class="el-upload__tip waring" slot="tip" v-show="tip">
        <span v-if="tip_text">请上传比例16:9的图片，否则会影响前台显示</span> <span>{{theCustom}}</span><br v-show="theCustom"> <br v-if="tip_text" />
        {{theText}}
      </div>
    </el-upload>
  </div>
</template>

<script>
export default {
  props: {
    action: {
      type: Object,
      default() {
        return {}
      }
    },
    banner: {
      type: String,
      default: ''
    },
    tip: {
      type: Boolean,
      default: true
    },
    tip_text:{
      type:Boolean,
      default:true
    },
    theCustom:{
        type:String,
        default:''
    },
    theText:{
      type:String,
      default:'大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片'
    }
  },
  data() {
    return {
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      // banner: '',
      // img:
      header: {
        Token: sessionStorage.getItem('token')
      }
    }
  },

  methods: {
    handleAvatarSuccess(res, file) {
      if (res.errorCode == 200) {
        // console.log(res.data.url)
        // this.img = res.data.url
        this.$emit('event', res.data.url)
        console.log(file)
      } else {
        this.$message.error(res.message)
      }
      // this.form.banner = URL.createObjectURL(file.raw)
    }
  }
  // computed: {
  //   img() {
  //     return this.banner
  //   }
  // }
  // mounted() {
  //   console.log(this.action)
  // }
}
</script>

<style lang="less" scoped>
.waring {
  color: #fda400;
  line-height: 24px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
</style>
